import { useMemo } from 'react';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { useTheme } from 'styled-components';

import { Box } from 'components/Box';
import { FlexContainer } from 'components/FlexContainer';
import { SignupLink } from 'components/Links';
import { Overlay } from 'components/Overlay';

import { useGatsbyImage } from 'hooks/useGatsbyImage';
import type { FC } from 'react';

interface ExitIntentOverlayProps {
  handleClose: () => void;
  utmSource?: string;
}

export const ExitIntentOverlay: FC<ExitIntentOverlayProps> = ({
  handleClose,
  utmSource = 'blog',
}) => {
  const image = useGatsbyImage({
    name: 'exitintentoverlay/hero',
    alt: 'Graphic vector of product and currencies',
  });

  const { breakpoints } = useTheme();

  const isMobile = useMediaQuery(breakpoints.down('md'));

  const utmParams = useMemo(() => {
    const searchParams = window.location.search;
    const urlSearchParams = new URLSearchParams(searchParams);

    if (urlSearchParams.get('utm_source')) return undefined;

    return [{ paramName: 'utm_source', paramValue: utmSource }];
  }, [utmSource]);

  return (
    <Overlay handleClose={handleClose}>
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        direction={isMobile ? 'column' : 'row'}
      >
        <Box>
          <h3>Sign up for free</h3>
          <p>Track your stocks, ETFs, dividends and currency all in one place.</p>
          <SignupLink asButton appearance="primary" urlParams={utmParams}>
            Sign up
          </SignupLink>
        </Box>
        {!isMobile && <Box maxWidth="350px">{image}</Box>}
      </FlexContainer>
    </Overlay>
  );
};
