import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useGatsbyImage } from 'hooks/useGatsbyImage';
import { useExitIntent } from 'hooks/useExitIntent';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { SignupLink } from 'components/Links';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { SupportedBrokersBlock } from 'components/SupportedBrokersBlock';
import { StatsBlock } from 'components/StatsBlock';
import { PricingBlock } from 'components/PricingBlock';
import { TrustPilot } from 'components/TrustPilot';
import { CTANotice } from 'components/Notice';
import { Cards } from 'components/Cards';
import { DividendCalculator as DividendCalculatorComponent } from 'components/DividendCalculator';
import { Box } from 'components/Box';
import { ExitIntentOverlay } from 'components/ExitIntentOverlay';

import { dividendCalculatorCards } from 'page-data/dividend-calculator';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const DividendCalculator: FC<LocalizedPageProps> = () => {
  const {
    translations: { trackedInvestments, stock },
  } = useContentfulLocale();

  const { showOverlay, handleClose } = useExitIntent('LP');

  return (
    <Layout>
      {showOverlay && <ExitIntentOverlay handleClose={handleClose} utmSource="LP" />}

      <Seo />

      <HeroContainer manageLayout={false}>
        <Section center>
          <h1>Dividend calculator</h1>
          <p>Estimate your monthly dividend income with Sharesight’s dividend calculator.</p>
          <br />
          <br />
        </Section>

        <Section>
          <DividendCalculatorComponent />
          <Box padding={5}>
            <small>
              *This dividend calculator is provided for informational and educational purposes only.
              It presents hypothetical scenarios and should not be considered as financial advice or
              a recommendation to buy or sell any security. The information and calculations
              provided by this tool are based on user inputs and general assumptions, creating
              hypothetical scenarios that may not reflect actual market conditions, tax
              implications, or individual circumstances. Investing involves risk, including the
              possible loss of principal. Past performance does not guarantee future results. The
              hypothetical scenarios generated by this calculator do not assure future performance
              or outcomes. Before making any investment decisions, please consult with a qualified
              financial advisor, tax professional, or investment specialist. <br />
              <br />
              We do not guarantee the accuracy, completeness, or timeliness of the information
              provided and are not responsible for any errors or omissions, or for the results
              obtained from the use of this hypothetical tool. By using this calculator, you
              acknowledge that you understand these hypothetical scenarios are for illustrative
              purposes only and agree to these terms.
            </small>
          </Box>
        </Section>
      </HeroContainer>

      <Cards cards={dividendCalculatorCards} />
      <Container>
        <Section>
          <h2>Track your dividends with Sharesight</h2>
          <p>
            Automatically track dividends and distributions for more than {trackedInvestments}{' '}
            {stock}s, ETFs and funds worldwide.
          </p>
          <SignupLink asButton showArrowIcon />
        </Section>
        <SectionImage
          image={useGatsbyImage({
            name: 'dividend-calculator/track-dividends',
            alt: 'Track your dividends with Sharesight',
          })}
          boxShadow={false}
        />
      </Container>
      <CTANotice />
      <SupportedBrokersBlock />
      <StatsBlock />
      <PricingBlock />
      <TrustPilot />
    </Layout>
  );
};

export default DividendCalculator;
